import './App.css';
import { Main } from './Main';
// import { Navbar } from './Navbar';
// import { Footer } from './Footer';

function App() {
  return (
    <div className="App">
      {/* <Navbar /> */}
      <Main />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
